import { cn } from '@my/magic-ui/src'
import { motion, AnimatePresence } from 'framer-motion'
import { X } from 'lucide-react'
import { useEffect } from 'react'
import { createPortal } from 'react-dom'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  showCloseButton?: boolean
  backLayerClassName?: string
  closeButtonClassName?: string
  closeButtonIconClassName?: string
  bodyClassName?: string
  mountToBody?: boolean
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  showCloseButton = true,
  backLayerClassName,
  closeButtonClassName,
  closeButtonIconClassName,
  mountToBody = true,
  bodyClassName,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  const modalContent = (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 z-[9999] flex items-center justify-center overflow-y-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'keyframes', damping: 25, stiffness: 300 }}
        >
          <motion.div
            className={cn('fixed inset-0 bg-black/50', backLayerClassName)}
            onClick={onClose}
          />
          <motion.div
            className={cn(
              'relative z-10 m-4 rounded-xl border border-white/10 bg-[#060606]',
              bodyClassName
            )}
            initial={{ y: '-100%' }}
            animate={{ y: 0 }}
            exit={{ y: '-100%' }}
            transition={{ type: 'keyframes', damping: 25, stiffness: 300 }}
          >
            <div className="custom-scrollbar max-h-[calc(100vh-2rem)] overflow-y-auto">
              {children}
            </div>
            {showCloseButton && (
              <div
                className={cn(
                  'absolute right-4 top-4 flex cursor-pointer items-center justify-center rounded-full border border-white/60 p-1 md:right-6 md:top-6',
                  closeButtonClassName
                )}
                onClick={onClose}
              >
                <X className={cn('text-white/60', closeButtonIconClassName)} />
              </div>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )

  if (typeof window === 'undefined') return null

  return mountToBody ? createPortal(modalContent, document.body) : modalContent
}

export default Modal
